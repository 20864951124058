<template>
  <div id="company" v-if="companySelected">
    <b-row>
      <b-col cols="12">
        <b-card class="card card-custom card-stretch gutter-b" no-body>
          <div class="card-body pt-15">
            <b-row>
              <b-col xl="4" lg="4" md="12" sm="12">
                <div class="text-center mb-10">
                  <div class="symbol symbol-60 symbol-xl-90">
                    <div class="symbol-label bg-transparent">
                      <img
                        v-if="companySelected.logo"
                        class=" max-h-100px max-w-200px"
                        :src="`${$baseUrl}${companySelected.logo}`"
                        :alt="`${companySelected.name} Logo`"
                      />
                    </div>
                  </div>
                  <h4 class="font-weight-bold my-2">
                    {{ companySelected.name }}
                  </h4>
                  <div>
                    <b-button
                      variant="secondary"
                      class="btn-icon btn-md btn-hover-text-primary rounded-circle mr-2"
                      @click="editCompany"
                    >
                      <i class="la la-pencil p-0" />
                    </b-button>
                    <b-button
                      v-if="isMyCompany"
                      variant="light-danger"
                      class="btn-icon btn-md btn-hover-text-primary rounded-circle"
                      @click="deleteCompany"
                    >
                      <i class="la la-trash p-0" />
                    </b-button>
                  </div>
                  <div class="d-flex align-items-center flex-column">
                    <span
                      class="label label-light-success label-inline font-weight-bold label-lg mt-10"
                    >
                      {{ companySelected.departmentsCount }}
                      {{ $t("menu.departments.title") }}
                    </span>
                    <span
                      class="label label-light-success label-inline font-weight-bold label-lg mt-10"
                    >
                      {{ companySelected.peopleCount }}
                      {{ $t("menu.users.title") }}
                    </span>
                  </div>
                </div>
                <div class="text-center mb-10">
                  <h3 class="card-title font-weight-bolder text-dark">
                    {{ $t("admin.dashboard.languages") }}
                  </h3>
                  <div class="flex flex-row">
                    <img
                      :src="require(`@/assets/images/flags/${language}.svg`)"
                      class="h-20px w-20px rounded-sm mr-3 mb-3"
                      v-for="language in companySelected?.environment?.languages
                        .list"
                      :key="language"
                      :alt="language"
                    />
                  </div>
                </div>
              </b-col>
              <b-col xl="4" lg="4" md="12" sm="12">
                <div class="text-center mb-10">
                  <h3 class="card-title font-weight-bolder text-dark">
                    {{ $t("admin.dashboard.contractedServices.title") }}
                  </h3>
                  <span
                    class="badge badge-light mt-2 mb-2 mr-4 font-size-md"
                    v-for="service in getServicesName(companySelected.services)"
                    :key="service"
                    >{{ service }}
                  </span>
                </div>
              </b-col>
              <b-col
                xl="4"
                lg="4"
                md="12"
                sm="12"
                v-if="childCompanies.length > 0"
              >
                <div class="text-center mb-10">
                  <h3 class="card-title font-weight-bolder text-dark">
                    {{ $t("admin.dashboard.childrenCompanies.title") }}
                  </h3>
                  <span
                    v-for="comp in childCompanies"
                    :key="comp.code"
                    class="badge badge-light mt-2 mb-2 mr-4 font-size-md"
                    >{{ comp.name }}</span
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="3" lg="3" md="12" sm="12">
        <div
          class="d-flex align-items-center justify-content-center"
          v-if="stakeholderRelationShip === 'RESELLER' && companies.length <= 1"
        >
          <b-button
            @click="createCompany()"
            variant="light-success"
            class="ml-2 font-weight-bold"
            v-b-tooltip.hover
            :title="$t('tables.create.company')"
          >
            <i class="p-0 fa fa-plus" />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  getMimetype,
  removeProfileData,
  checkCompanies,
  getError
} from "@/utils/helpers";
import {
  deleteStakeholder,
  getCompanies,
  getServices
} from "@/api/admin/stakeholders.api";
import { getStakeholderData } from "@/api/stakeholders.api";

export default {
  name: "Company",
  data() {
    return {
      stakeholderId: localStorage.getItem("companyId"),
      stakeholderRelationShip: localStorage.getItem("stakeholderRelationShip"),
      childCompanies: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Admin", [
      "isCompanySelectedLoaded",
      "companySelected",
      "companies"
    ])
  },
  methods: {
    ...mapActions("Admin", ["loadAdminCompanies", "loadCompanySelected"]),
    getServicesName(services) {
      const allServices = getServices();
      let result = [];
      _.each(services, service => {
        var services = _.find(allServices, { id: service });
        if (services) result.push(services.name);
      });
      return result;
    },
    isMyCompany() {
      return (
        localStorage.getItem("managedCompanyId") ==
        localStorage.getItem("companyId")
      );
    },
    createCompany() {
      this.$router.push({ name: "CompanyCreate" });
    },
    editCompany() {
      this.$router.push({
        name: "CompanyUpdate",
        params: {
          id: localStorage.getItem("managedCompanyId")
        }
      });
    },
    callGetMimetype(base64) {
      return getMimetype(base64);
    },
    deleteCompany() {
      this.$swal({
        title: this.title,
        html:
          this.$t("admin.popup.delete.companyChildren") +
          "<br>" +
          "<br>" +
          this.$t("admin.popup.delete.companyChildrenConfirm", {
            name: this.companySelected.name
          }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          let adminProfiles = [];
          if (value === this.companySelected.name) {
            deleteStakeholder("companies", this.companySelected.stakeholderId)
              .then(() => {
                axios.all([getStakeholderData()]).then(
                  axios.spread(stakeholderDataResp => {
                    adminProfiles =
                      stakeholderDataResp.data.records.profiles.ADMIN;
                    localStorage.setItem(
                      "adminProfiles",
                      _.toString(adminProfiles)
                    );
                    this.$store.dispatch("Admin/setCompany", false);
                    checkCompanies(adminProfiles);
                    removeProfileData();
                    this.loadAdminCompanies();
                    adminProfiles.length > 1
                      ? this.$router.push({ name: "AdminCompaniesManagement" })
                      : this.$router.push({ name: "Company" });
                  })
                );
              })
              .catch(error => {
                this.$root.$bvToast.toast(
                  this.$t("admin.popup.delete.company_has_children"),
                  {
                    title: getError(
                      error.response.data.records.applicationCode
                    ),
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center"
                  }
                );
              });
          } else {
            return this.$t("admin.popup.incorrectCompanyChildrenName", {
              name: this.companySelected.name
            });
          }
        }
      });
    },
    getChildrenCompanies() {
      getCompanies(localStorage.getItem("managedCompanyId"), {
        name: true
      }).then(response => {
        this.childCompanies = _.orderBy(response.data.records, company => {
          return _.toLower(company.name);
        });
      });
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadCompanySelected();
        this.getChildrenCompanies();
      }
    },
    isCompanySelectedLoaded(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.loadCompanySelected();
        this.getChildrenCompanies();
      }
    }
  },
  mounted() {
    if (this.companySelected != false && this.isConstantsActive) {
      this.loadCompanySelected();
      this.getChildrenCompanies();
    }
  }
};
</script>

<style lang="scss">
.company-logo {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
.card-max-height {
  max-height: 75vh;
}

.card-overflow {
  overflow: scroll;
}
</style>
